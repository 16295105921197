import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCKQttZJG9H439awlAbR9y2fqvyqRGnzDA",
  authDomain: "crics-grid.firebaseapp.com",
  projectId: "crics-grid",
  storageBucket: "crics-grid.appspot.com",
  messagingSenderId: "137593148530",
  appId: "1:137593148530:web:3f8806e61b208725581eeb",
  measurementId: "G-J2T09H6H9E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firebase Messaging only if supported
let messaging = null;
if (
  isSupported() &&
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window
) {
  messaging = getMessaging(app);
}

export { auth, messaging };
