import React from 'react'
import { AppShell, Center, Tabs } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks'
import ReactGA from "react-ga4";

import Header from './Header';
import Main from './Main';

const GamePlay = () => {

    ReactGA.send({ hitType: "pageview", page: "/", title: "Game" });

    const [instruction, setInstruction] = React.useState('')
    const [activeTab, setActiveTab] = React.useState('ipl');

    const isSmallScreen = useMediaQuery('(max-width: 767px)');

    return (
        <AppShell
            header={{ height: 60 }}
            padding="md"
        >
            <AppShell.Header>
                <Header instruction={instruction} activeTab={activeTab} setActiveTab={setActiveTab} />
            </AppShell.Header>
            <AppShell.Main bg={'#F7F7F7'}>
                {isSmallScreen &&
                    <Center style={{marginBottom: '1rem'}}>
                        <Tabs value={activeTab} onChange={setActiveTab} >
                            <Tabs.List>
                                <Tabs.Tab value="ipl">IPL</Tabs.Tab>
                                <Tabs.Tab value="t20wc">T20 World Cup</Tabs.Tab>
                            </Tabs.List>
                        </Tabs>
                    </Center>
                }
                <Main setInstruction={setInstruction} activeTab={activeTab} />
            </AppShell.Main>
        </AppShell>
    )
}

export default GamePlay
