import React from 'react'

import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import ReactGA from "react-ga4";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { AuthProvider } from './AuthContext';
import GamePlay from './Components/GamePlay';
import CommonPlayers from './Components/CommonPlayers';
import InitScreen from './Components/Initscreen';
import Logout from './Components/Logout';

ReactGA.initialize("G-E4RV8H7QJE");


function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <GamePlay />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/game",
      element: <GamePlay />,
    },
    {
      path: "/players",
      element: <CommonPlayers />,
    },
  ]);

  return (
    <AuthProvider>
      <MantineProvider
        theme={{
          fontFamily: 'Verdana, sans-serif',
          fontFamilyMonospace: 'Monaco, Courier, monospace',
          headings: { fontFamily: 'Greycliff CF, sans-serif' },
        }}>
        <RouterProvider router={router} />
      </MantineProvider>
    </AuthProvider>
  );
}

export default App;
